<template>
  <div class="text-center">
    <div class="flex justify-center gap-x-3">
      <a href="https://www.instagram.com/polyroid.app/"><img class="w-full" style="max-width: 24px" src="/assets/images/icons/instagram.svg" alt="Instagram Logo mit Link zur Polyroid Instagram Seite"></a>
      <a href="https://www.tiktok.com/@polyroid.app?lang=de-DE"><img class="w-full" style="max-width: 24px" src="/assets/images/icons/tiktok.svg" alt="TikTok Logo mit Link zur Polyroid TikTok Seite"></a>
    </div>
    <div class="p-1"></div>
    <div class="flex justify-center gap-x-2 items-baseline">
     <a href="https://20north.de/legal-notice/">Impressum</a>
      |
      <a href="https://20north.de/privacy-policy/">Datenschutz</a>
    </div>
    <div class="p-1"></div>
    <h4>© 2023 20North Germany UG</h4>
    <div class="p-4"></div>
  </div>
</template>

<script>
  export default {
  name: "footerLandingpage",
  }

</script>
