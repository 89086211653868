<!--
    Example import of alert
     <alert
        type="" (success/info/warning/error)
        title="" (Title of Message)
        message="" (Message)
        link="" (URL of Link)
        button="" (Title of Link)
        action1Title="" (Title of Button 1)
        action2Title=""  (Title of Button 2)
        @action1="" (called a function in the View by click on Button 1)
        @action2="" (called a function in the View by click on Button 2)
    />
-->
<template>
    <div class="rounded-md bg-green-50 p-4" v-if="visibility && type == 'success'">
        <div class="flex">
            <div class="flex-shrink-0">
                <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">{{title}}</h3>
                <div class="mt-2 text-sm text-green-700">
                    <p>{{message}}</p>
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button @click="visibility = false" type="button" class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                        <span class="sr-only">Dismiss</span>
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="ml-auto pl-3">
                <p class="mt-3 text-sm md:ml-6 md:mt-0" v-if="button">
                    <a :href="link" class="whitespace-nowrap font-medium text-green-700 hover:text-green-600">
                        {{ button }}
                        <span aria-hidden="true"> &rarr;</span>
                    </a>
                </p>
            </div>
        </div>
        <div class="mt-4 ml-auto pl-8" v-if="action1Title || action2Title">
            <div class="-mx-2 -my-1.5 flex">
                <button type="button" @click="$emit('action1')" class="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                    {{ action1Title }}
                </button>
                <button type="button" @click="$emit('action2')" class="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                    {{ action2Title }}
                </button>
            </div>
        </div>
    </div>
    <div class="rounded-md bg-blue-50 p-4" v-if="visibility && type == 'info'">
        <div class="flex">
            <div class="flex-shrink-0">
                <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <h3 class="text-sm font-medium text-blue-800">{{title}}</h3>
                <div class="mt-2 text-sm text-blue-700">
                    <p>{{message}}</p>
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button @click="visibility = false" type="button" class="inline-flex rounded-md bg-blue-50 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50">
                        <span class="sr-only">Dismiss</span>
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="ml-auto pl-3">
                <p class="mt-3 text-sm md:ml-6 md:mt-0" v-if="button">
                    <a :href="link" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                        {{ button }}
                        <span aria-hidden="true"> &rarr;</span>
                    </a>
                </p>
            </div>
        </div>
        <div class="mt-4 ml-auto pl-8" v-if="action1Title || action2Title">
            <div class="-mx-2 -my-1.5 flex">
                <button type="button" @click="$emit('action1')" class="rounded-md bg-blue-50 px-2 py-1.5 text-sm font-medium text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50">
                    {{ action1Title }}
                </button>
                <button type="button" @click="$emit('action2')" class="ml-3 rounded-md bg-blue-50 px-2 py-1.5 text-sm font-medium text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50">
                    {{ action2Title }}
                </button>
            </div>
        </div>
    </div>
    <div class="rounded-md bg-yellow-50 p-4" v-if="visibility && type == 'warning'">
        <div class="flex">
            <div class="flex-shrink-0">
                <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <h3 class="text-sm font-medium text-yellow-800">{{title}}</h3>
                <div class="mt-2 text-sm text-yellow-700">
                    <p>{{message}}</p>
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button @click="visibility = false" type="button" class="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">
                        <span class="sr-only">Dismiss</span>
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="ml-auto pl-3">
                <p class="mt-3 text-sm md:ml-6 md:mt-0" v-if="button">
                    <a :href="link" class="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600">
                        {{ button }}
                        <span aria-hidden="true"> &rarr;</span>
                    </a>
                </p>
            </div>
        </div>
        <div class="mt-4 ml-auto pl-8" v-if="action1Title || action2Title">
            <div class="-mx-2 -my-1.5 flex">
                <button type="button" @click="$emit('action1')" class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">
                    {{ action1Title }}
                </button>
                <button type="button" @click="$emit('action2')" class="ml-3 rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">
                    {{ action2Title }}
                </button>
            </div>
        </div>
    </div>
    <div class="rounded-md bg-red-50 p-4" v-if="visibility && type == 'error'">
        <div class="flex">
            <div class="flex-shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">{{title}}</h3>
                <div class="mt-2 text-sm text-red-700">
                    <p>{{message}}</p>
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button @click="visibility = false" type="button" class="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                        <span class="sr-only">Dismiss</span>
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="ml-auto pl-3">
                <p class="mt-3 text-sm md:ml-6 md:mt-0" v-if="button">
                    <a :href="link" class="whitespace-nowrap font-medium text-red-700 hover:text-red-600">
                        {{ button }}
                        <span aria-hidden="true"> &rarr;</span>
                    </a>
                </p>
            </div>
        </div>
        <div class="mt-4 ml-auto pl-8" v-if="action1Title || action2Title">
            <div class="-mx-2 -my-1.5 flex">
                <button type="button" @click="$emit('action1')" class="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                    {{ action1Title }}
                </button>
                <button type="button" @click="$emit('action2')" class="ml-3 rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                    {{ action2Title }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { CheckCircleIcon, XMarkIcon, XCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
</script>
<script>
export default {
    name: "alert",
    props: {
        type: String,
        title: String,
        message: String,
        link: String,
        button: String,
        action1Title: String,
        action2Title: String,
    },
    emits: ['action1', 'action2'],
    data() {
        return {
            visibility: true
        }
    },
}
</script>

<style scoped>

</style>