<template>
  <div class="">
    <div class="">
      <headerLandingpage class="flex-grow"></headerLandingpage>
    </div>
    <div class="lg:max-w-screen-xl 3xl:ml-unset 3xl:mx-auto m-auto">
      <div class="p-3"></div>
      <div class="px-6">
        <alert
            type="info"
            title="Der Start von Polyroid ist für September 2023 geplant."
        />
      </div>
      <section class="hero mt-8 px-6">
        <div class="lg:flex lg:flex-row-reverse lg:justify-center">
          <div class="">
            <img src="/assets/images/landingpageImages/poly-images.png" alt="">
          </div>
          <div class="mt-20">
            <h6 class="text-lg text-gray-500 lg:text-xl" @click="config.english = !config.english">{{ config.english?'Love multiple, not limited':'Liebe multiplizieren, nicht limitieren' }}</h6>
            <h4 class="text-4xl uppercase leading-snug font-bold my-4 lg:text-6xl lg:leading-snug">Dein Weg zur Vielfalt</h4>
            <p class="leading-7 text-gray-500">Entdecke eine Welt voller spannender Beziehungen jenseits der Norm. </p>
            <div class="p-2"></div>
            <a href="https://www.kickstarter.com/projects/20northgermany/polyroid-app-liebe-multiplizieren-nicht-limitieren" target="_blank" class="mt-10 w-60 block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Jetzt unterstützen 🥳</a>
          </div>
        </div>
        <div class="p-8 lg:p-2"></div>
        <div class="lg:grid lg:grid-cols-4 lg:gap-x-8">
          <div class="">
            <div class="flex justify-start gap-x-3 mb-2">
              <h3 class="text-lg"> 🌈</h3>
              <h3 class="font-semibold text-lg">Vielfalt</h3>
            </div>
            <p class="text-gray-500">Entdecke die Vielfalt der
              Beziehungsmodelle und
              finde individuelle Wege,
              deine Polyamorie zu leben.</p>
          </div>
          <div class="p-4 lg:hidden"></div>
          <div class="text-left">
            <div class="flex justify-start  gap-x-3 mb-2">
              <h3 class="text-lg">🤝</h3>
              <h3 class="font-semibold text-lg">Verbindung</h3>
            </div>
            <p class="text-gray-500">Perfekte Matches finden, in
              Echtzeit chatten und
              gemeinsam in aufregenden
              Beziehungen wachsen.</p>
          </div>
          <div class="p-4 lg:hidden"></div>
          <div class="text-left">
            <div class="flex justify-start gap-x-3 mb-2">
              <h3 class="text-lg">✨</h3>
              <h3 class="font-semibold text-lg">Abenteuer</h3>
            </div>
            <p class="text-gray-500">Erlebe aufregende
              Abenteuer und
              unvergessliche Momente in
              deinen Beziehungen</p>
          </div>
        </div>
      </section>
      <div class="mt-24 sm:mt-32"></div>
      <div class="" id="features">
        <article class="bg-gray-100 px-5 pt-6 rounded-md px-6 pt-24 lg:flex lg:px-16 lg:gap-x-32 lg:mx-6">
          <div class="mb-6 text-center lg:text-left lg:flex-1 sm:pt-24">
            <div class="pb-3">
              <h3 class="text-5xl pb-3">🙌🏼</h3>
              <h3 class="font-semibold text-xl lg:text-3xl lg:font-bold">Erstelle mit deinen Partnern ein gemeinsames Polyprofile</h3>
            </div>
            <p class="text-gray-500">Beginne deine Reise mit einem individuellen Profil, um dich als einzigartige Person darzustellen. Oder trete einem Polyprofil bei, um mit deinen Partnern gemeinsam neue Menschen kennenzulernen. Zeige deine Interessen, Vorlieben und Beziehungsdynamiken und finde gleichgesinnte Menschen, die deine Vielfalt schätzen.</p>
          </div>
          <div class="lg:relative flex-2 lg:pt-4">
            <h2 class="hidden lg:block lg:absolute lg:-left-16 font-bold lg:z-10 opacity-20" style="font-size: 300px">1</h2>
            <img class="w-full m-auto lg:z-20 lg:relative" style="max-width: 290px;" src="/assets/images/landingpageImages/mockups/step1.png" alt="">
          </div>
        </article>

        <div class="p-6 lg:p-12"></div>

        <article class="bg-gray-100 px-5 pt-6 rounded-md px-6 pt-24 lg:flex lg:px-16 lg:gap-x-32 lg:mx-6 lg:flex-row-reverse">
          <div class="mb-6 text-center lg:text-left lg:flex-1 sm:pt-24">
            <div class="pb-3">
              <h3 class="text-5xl pb-3">😍</h3>
              <h3 class="font-semibold text-xl lg:text-3xl lg:font-bold">Matche andere
                Leute oder Polyprofile</h3>
            </div>
            <p class="text-gray-500">Entdecke passende Verbindungen, ganz nach deinen Bedürfnissen. Ob du als Einzelperson jemanden kennenlernen möchtest oder in einem Polykontext nach erweiterten Beziehungen suchst, unsere Matching-Funktion hilft dir, kompatible Menschen zu finden. Passe deine Vorlieben an, erkunde verschiedene Profile und finde Menschen, die deine Lebensweise verstehen und respektieren.</p>
          </div>
          <div class="lg:relative flex-2 lg:pt-4">
            <h2 class="hidden lg:block lg:absolute lg:-right-20 font-bold lg:z-10 opacity-20" style="font-size: 300px">2</h2>
            <img class="w-full m-auto lg:z-20 lg:relative" style="max-width: 290px;" src="/assets/images/landingpageImages/mockups/step3.png" alt="">
          </div>
        </article>

        <div class="p-6 lg:p-12"></div>

        <article class="bg-gray-100 px-5 pt-6 rounded-md px-6 pt-24 lg:flex lg:px-16 lg:gap-x-32 lg:mx-6">
          <div class="mb-6 text-center lg:text-left lg:flex-1 sm:pt-24">
            <div class="pb-3">
              <h3 class="text-5xl pb-3">💬</h3>
              <h3 class="font-semibold text-xl lg:text-3xl lg:font-bold">Entdecke den Einzel-
                und Gruppenchat</h3>
            </div>
            <p class="text-gray-500">Vertiefe deine Verbindungen durch private Einzelgespräche oder tausche dich in Gruppenchats aus. Ob du dich alleine austauschen möchtest oder in einer Gruppe mit deinen Partnern und anderen neuen Kontakten interagieren willst, unsere Chat-Funktionen bieten dir den Raum für bedeutungsvolle Gespräche und ermöglichen es dir, neue Perspektiven kennenzulernen und authentische Beziehungen aufzubauen.</p>
          </div>
          <div class="lg:relative flex-2 lg:pt-4">
            <h2 class="hidden lg:block lg:absolute lg:-left-20 font-bold lg:z-10 opacity-20" style="font-size: 300px">3</h2>
            <img class="w-full m-auto lg:z-20 lg:relative" style="max-width: 290px;" src="/assets/images/landingpageImages/mockups/step2.png" alt="">
          </div>
        </article>
      </div>

      <section class="labelBackground bg-[image-url] relative my-24 sm:my-32 absolute inset-0 flex justify-center items-center" style="height: 100vh;">
        <div class="bg-white mx-6 px-8 py-14 top-14 text-center lg:w-6/12 rounded-md">
          <h4 class="font-semibold text-xl mb-3">Bereit für den Start von Polyroid?</h4>
          <p class="text-gray-500 mb-4">Der Start von Polyroid ist für September 2023 geplant. Vorher starten wir im August eine Closed- und eine Open-Beta. Hier kannst du Polyroid testen und uns aktives Feedback geben. Du gestaltest also direkt die Zukunft von Polyroid mit. 😍</p>
          <router-link to="/register" class="mt-10 block w-64 m-auto rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Jetzt Teil der Beta werden 🥳</router-link>
        </div>
      </section>

      <section>
        <div class="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-primaryLabelColor sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div class="p-8 sm:p-10 lg:flex-auto">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900">Nur du kannst uns helfen 🥺</h3>
            <p class="mt-6 text-base leading-7 text-gray-600">Polyroid, unsere Polyamore Dating App, ist ein Herzensprojekt, das die Welt der Beziehungen bereichern soll. Doch um sie weiterzuentwickeln und bekannt zu machen, benötigen wir finanzielle Unterstützung – und darauf hoffen wir von ganzem Herzen, dass du uns dabei hilfst! Jede Spende bringt uns näher zu unserer Vision einer offenen, liebevollen und vielfältigen Beziehungswelt.</p>
            <div class="mt-10 flex items-center gap-x-4">
              <h4 class="flex-none text-sm font-semibold leading-6 text-primary">Was bekommst du als Dank von uns?</h4>
              <div class="h-px flex-auto bg-gray-100" />
            </div>
            <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              <li v-for="feature in includedFeatures" :key="feature" class="flex gap-x-3">
                <CheckIcon class="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                {{ feature }}
              </li>
            </ul>
          </div>
          <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0" id="donate">
            <div class="rounded-2xl h-full bg-softbg py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div class="mx-auto max-w-xs px-8">
                <p class="text-base font-semibold text-gray-600">Polyroid unterstützen <br>schon ab</p>
                <p class="mt-6 flex items-baseline justify-center gap-x-2">
                  <span class="text-5xl font-bold tracking-tight text-gray-900">Ab 1</span>
                  <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">Euro</span>
                </p>
                <a href="https://www.kickstarter.com/projects/20northgermany/polyroid-app-liebe-multiplizieren-nicht-limitieren" target="_blank" class="mt-10 block w-full rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Jetzt unterstützen 🥳</a>
                <p class="mt-6 text-xs leading-5 text-gray-600">Der Start der App ist für September 2023 geplant. Das Crowdfunding läuft über die Plattform Kickstarter.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

<!--      <section>-->
<!--        <div class="bg-white my-24 sm:my-32">-->
<!--          <div class="mx-auto max-w-7xl px-6 lg:px-8">-->
<!--            <div class="mx-auto max-w-4xl text-center">-->
<!--              <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Grenzenlose Möglichkeiten mit Polyroid+</p>-->
<!--            </div>-->
<!--            <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">Entdecke das Upgrade, das dir unendlich viele Möglichkeiten eröffnet und dich noch näher zu deinen perfekten Matches bringt.</p>-->
<!--            <div class="mt-16 flex justify-center">-->
<!--              <RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">-->
<!--                <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>-->
<!--                <RadioGroupOption as="template" v-for="option in frequencies" :key="option.value" :value="option" v-slot="{ checked }">-->
<!--                  <div :class="[checked ? 'bg-primary text-white' : 'text-gray-500', 'cursor-pointer rounded-full px-2.5 py-1']">-->
<!--                    <span>{{ option.label }}</span>-->
<!--                  </div>-->
<!--                </RadioGroupOption>-->
<!--              </RadioGroup>-->
<!--            </div>-->
<!--            <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">-->
<!--              <div v-for="tier in tiers" :key="tier.id" :class="[tier.mostPopular ? 'ring-2 ring-primary' : 'ring-1 ring-gray-200', 'rounded-3xl p-8 xl:p-10']">-->
<!--                <div class="flex items-center justify-between gap-x-4">-->
<!--                  <h3 :id="tier.id" :class="[tier.mostPopular ? 'text-primary' : 'text-gray-900', 'text-lg font-semibold leading-8']">{{ tier.name }}</h3>-->
<!--                  <p v-if="tier.mostPopular" class="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primary">😍 Beliebt</p>-->
<!--                </div>-->
<!--                <p class="mt-4 text-sm leading-6 text-gray-600">{{ tier.description }}</p>-->
<!--                <p class="mt-6 flex items-baseline gap-x-1">-->
<!--                  <span class="text-4xl font-bold tracking-tight text-gray-900">{{ tier.price[frequency.value] }}</span>-->
<!--                  <span v-if="tier.name !== 'Polyroid Free'" class="text-sm font-semibold leading-6 text-gray-600">{{ frequency.priceSuffix }}</span>-->
<!--                </p>-->
<!--                <a :href="tier.href" :aria-describedby="tier.id" v-if="tier.name !== 'Polyroid Free'" :class="[tier.mostPopular ? 'bg-primary text-white shadow-sm hover:bg-secondary hover:text-primary' : 'text-primary ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">Jetzt Upgraden</a>-->
<!--                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">-->
<!--                  <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">-->
<!--                    <CheckIcon class="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />-->
<!--                    {{ feature }}-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </section>-->

      <section>
        <div class="bg-white my-24 sm:my-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
              <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Fragen und Antworten rund um Polyroid</h2>
              <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
                <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
                  <dt>
                    <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
                      <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                      <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
                    </DisclosureButton>
                  </dt>
                  <DisclosurePanel as="dd" class="mt-2 pr-12">
                    <p class="text-base leading-7 text-gray-600">{{ faq.answer }}</p>
                  </DisclosurePanel>
                </Disclosure>
              </dl>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>

<script>

import headerLandingpage from "@/components/header/headerLandingpage";
import FooterLandingpage from "@/components/footer/footerLandingpage";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import alert from "@/components/alerts/alert";
import { ref } from 'vue';
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";
import {userStore} from "@/store/userStore";
/* eslint-disable */
export default {
  name: "home",
  title:"Startseite",
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  components: {FooterLandingpage, headerLandingpage, alert, RadioGroup, RadioGroupLabel, RadioGroupOption, CheckIcon, Disclosure, DisclosureButton, DisclosurePanel, MinusSmallIcon, PlusSmallIcon},
  data(){

      const frequencies = [
        { value: 'monthly', label: 'monatlich', priceSuffix: '/Monat' },
        { value: 'annually', label: 'jährlich', priceSuffix: '/Jahr' },
      ];

      const tiers = [
        {
          name: 'Polyroid Free',
          id: 'tier-freelancer',
          href: '#',
          price: { monthly: 'Kostenlos', annually: 'Immer noch Kostenlos 🙃' },
          description: 'Erlebe Polyroid kostenlos und entdecke eine Welt voller Polyamorie-Verbindungen',
          features: ['Begrenzte Anzahl an Matches', 'Standard Filteroptionen', 'Einzel- und Gruppenchat'],
          mostPopular: false,
        },
        {
          name: 'Polyroid +',
          id: 'tier-startup',
          href: '#',
          price: { monthly: '7,99€', annually: '84,99€' },
          description: 'Erlebe Polyroid kostenlos und entdecke eine Welt voller Polyamorie-Verbindungen.',
          features: [
            'Unbegrenzte Anzahl an Matches',
            'Erweitere Filteroptionen',
            'Sehe, wer dich matcht',
            'Verwende Superlikes mit individuellen Nachrichten, um dein Interesse besonders hervorzuheben',
            'Zugang zum Closed-Beta Programm'
          ],
          mostPopular: true,
        },
        {
          name: 'Polyroid Unterstützer',
          id: 'tier-enterprise',
          href: '#',
          price: { monthly: '2,99€', annually: '29,99€' },
          description: 'Werden Sie Polyroid-Unterstützer, erhalten Sie ein besonderes Profilbadge und unser herzlicher Dank für Ihre monatliche Unterstützung.',
          features: [
            'Unendliche Dankbarkeit',
            'Unterstützer Badge im Profil',
            'Einen Platz in der öffentlichen Unterstützerliste',
            'Zugang zum Closed-Beta Programm'
          ],
          mostPopular: false,
        },
      ];

    const includedFeatures = [
      'Schnellere Entwicklung der App',
      'Unbegrenzte Polyroid + Mitgliedschaft',
      'Unterstützer-Label auf deinem Profil',
      'Dein Feedback hat direkten Einfluss auf die Entwicklung der App',
    ];

    const frequency = ref(frequencies[0]);

    const faqs = [
      {
        question: "Was ist Polyamorie?",
        answer:
            "Polyamorie ist eine Beziehungspraxis, bei der Menschen gleichzeitig und mit dem Einverständnis aller Beteiligten romantische oder liebevolle Beziehungen zu mehreren Partnern führen. Es geht um Offenheit, Ehrlichkeit und die Möglichkeit, mehrere Verbindungen aufzubauen, die auf Vertrauen und gegenseitigem Respekt basieren.",
      },
      {
        question: "Was ist Polyroid?",
        answer:
            "Polyroid ist eine fortschrittliche Dating-App, die speziell für polyamore Beziehungen entwickelt wurde. Mit Polyroid kannst du Menschen treffen, die deine Vorstellungen von Beziehungen teilen und eine offene, ehrliche und respektvolle Form der Liebe praktizieren. Die App bietet Funktionen zur Erstellung von Einzel- und Polyprofilen, zum Matchen mit potenziellen Partnern und zum Starten von Einzel- oder Gruppenchats, um dich mit anderen Polyamorie-Enthusiasten zu verbinden.",
      },
      {
        question: "Kostet Polyroid etwas?",
        answer:
            "Die Nutzung von Polyroid ist grundsätzlich kostenlos. Du kannst die App kostenlos herunterladen und grundlegende Funktionen wie das Erstellen eines Profils, das Durchsuchen von Profilen und das Matchen mit anderen Benutzern nutzen. Es gibt jedoch auch Premium-Funktionen und Optionen, für die Kosten anfallen können. Diese erweiterten Funktionen können zusätzliche Vorteile bieten und dir helfen, deine polyamoren Beziehungen zu vertiefen und zu erweitern.",
      },
      {
        question: "Wer kann Polyroid nutzen?",
        answer:
            "Polyroid ist für Menschen ab 18 Jahren, jeder sexuellen Orientierung und Geschlechtsidentität zugänglich, die in polyamoren Beziehungen leben, daran interessiert sind oder nach polyamoren Partnern suchen. Wir möchten eine inklusive Plattform schaffen, die Menschen zusammenbringt und ihnen die Möglichkeit gibt, ihre polyamoren Beziehungen zu erforschen und zu erweitern.",
      },
      {
        question: "Wo gibt es Polyroid?",
        answer:
            "Aktuell befindet sich Polyroid noch in der Entwicklung. Der geplante der App ist für September 2023 angedacht.",
      },
      {
        question: "Wie kann ich Polyroid unterstützen?",
        answer:
            "Wir haben eine Kickstarterkampagne gestartet. Hier kannst du uns unterstützen. Alternativ hilft uns bereits ein Follow auf einem unserer Social-Media Kanälen.",
      },
      // More questions...
    ]

    return {
      frequencies,
      tiers,
      frequency,
      faqs,
      includedFeatures,
      };
  }
}

</script>

<style scoped>

.labelBackground {
  position: relative;
  width: 100%;
  height: 40vh;
}

.labelBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/assets/images/landingpageImages/landingpage-label-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.4;
  z-index: -1;
}


</style>