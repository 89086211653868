import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

import profile from "@/views/profile/profile";
import settings from "@/views/settings";
import login from "@/views/login";
import questionsOverview from "@/views/profile/profileQuestions/questionsOverview";
import questionsnewAnswer from "@/views/profile/profileQuestions/newAnswer";
import home from "@/views/landingpage/home";
import linkCollection from "@/views/landingpage/linkCollection";
import headerLandingpage from "@/components/header/headerLandingpage";
import footerLandingpage from "@/components/footer/footerLandingpage";
import dashboard from "@/views/dashboard";
import registerBefore from "@/views/registerBefore";
import register from "@/views/register";
import notfound from "@/views/404";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";


const routes = [
  {
    path: '/login',
    alias: '/login',
    name: 'Login',
    components: {
      default: login,
    },
    meta: { noAuth: true },
  },
  {
    path: '/',
    alias: '/home',
    name: 'home',
    components: {
      default: home,
      // navBar: headerLandingpage,
      footer: footerLandingpage
    },
    meta: { noAuth: true },
  },

  {
    path: '/links',
    name: 'linkCollection',
    components: {
      default: linkCollection,
      navBar: headerLandingpage,
      footer: footerLandingpage
    },
    meta: { noAuth: true },
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashboard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      default: registerBefore,
    },
    meta: { noAuth: true },
  },
  {
    path: '/profile',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profile,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/profile/questions',
    name: 'QAndA',
    components: {
      navBar: navBar,
      default: questionsOverview,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/profile/questions/new',
    name: 'QAndANew',
    components: {
      navBar: navBar,
      default: questionsnewAnswer,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      navBar: navBar,
      default: settings,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    name: 'notfound',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    components: {
      navBar: headerLandingpage,
      default: notfound,
      footer: footerLandingpage,
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  config.menuModal = false

  window.scrollTo(0, 0)
  window.onscroll = function () {}

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch(e =>{
          console.log(e)
        })
        .finally(() => {
          config.loading = true
          next()
        })
  }
})

let history = []
router.afterEach((to, from) => {
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router
