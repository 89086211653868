<template>
  <div class="lg:max-w-screen-xl 3xl:ml-unset 3xl:mx-auto m-auto h-96">
    <iframe class="m-auto" src="https://embed.lottiefiles.com/animation/91726"></iframe>
    <h2 class="text-center text-2xl font-bold"> Oh nein 😥</h2>
    <h3 class="text-center text-lg">die von dir ausgewählte Seite konnte leider nicht gefunden werden.</h3>
    <div class="p-2"></div>
    <button @click="goBack" class="flex m-auto justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
      Zurück
    </button>
  </div>
</template>

<script>
export default {
  name: '404',
  title: '404 Error',
  mounted () {
    this.config.loading = false
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style scoped>

</style>
