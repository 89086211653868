<template>
  <div>
<!--    1-->
    <div class="max-w-md m-auto px-6">
      <div class="p-4"></div>
      <div class="p-4"></div>
      <h2 class="text-center font-semibold text-3xl">Polyroid.app Linktree</h2>
      <div class="p-4"></div>
      <div class="flex flex-wrap justify-center gap-y-6">

        <a href="https://www.kickstarter.com/projects/20northgermany/polyroid-app-liebe-multiplizieren-nicht-limitieren" class="w-full">
          <div class="rounded-xl bg-gray-100 px-7 pt-4 pb-4 border-4 border-primary lg:pb-8">
            <div class="relative lg:flex">
              <div class="">
                <h2 class="text-black font-bold text-2xl mb-2 pt-10">Polyroid Crowdfunding 😍</h2>
                <div class="border-l-4 border-green-400 bg-green-50 p-4 mt-4">
                  <p class="font-semibold pb-1">Was ist Crowdfunding?</p>
                  <p class="">Crowdfunding ist eine Methode, bei der Menschen gemeinsam Geld für Projekte sammeln, um ihre Projekte wie Polyroid zu verwirklichen und positive Veränderungen in der Welt zu bewirken - sei auch du Teil dieser Bewegung und unterstütze Polyroid mit einer Spende! </p>
                </div>
                <button class="flex justify-center mt-5 block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Jetzt unterstützen ➔</button>
              </div>
              <img src="/assets/images/icons/polyroidIcon.svg" alt="Polyroid.app Icon" class="opacity-10 absolute top-1 -right-2" style="max-width: 120px">
              <img src="" alt="" class="hidden lg:block">
            </div>
          </div>
        </a>

        <a href="https://www.tiktok.com/@polyroid.app?lang=de-DE" class="w-full">
          <div class="rounded-xl bg-gray-100 px-7 pt-4 pb-4 lg:pb-8">
            <div class="relative lg:flex">
              <div class="">
                <h2 class="text-black font-bold text-2xl mb-2 pt-10">Polyroid auf<br>TikTok</h2>
                <button class="flex justify-center mt-4 block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Jetzt folgen</button>
              </div>
              <img src="/assets/images/icons/tiktok.svg" alt="Polyroid.app Icon" class="opacity-10 absolute top-1 -right-2" style="max-width: 100px">
              <img src="" alt="" class="hidden lg:block">
            </div>
          </div>
        </a>

        <a href="https://www.instagram.com/polyroid.app/" class="w-full">
          <div class="rounded-xl bg-gray-100 px-7 pt-4 pb-4 lg:pb-8">
            <div class="relative lg:flex">
              <div class="">
                <h2 class="text-black font-bold text-2xl mb-2 pt-10">Polyroid auf<br>Instagram</h2>
                <button class="flex justify-center mt-4 block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Jetzt folgen</button>
              </div>
              <img src="/assets/images/icons/instagram.svg" alt="Polyroid.app Icon" class="opacity-10 absolute top-1 -right-2" style="max-width: 100px">
              <img src="" alt="" class="hidden lg:block">
            </div>
          </div>
        </a>
        <a href="https://polyroid.app" class="w-full">
          <button class="flex w-full justify-center mt-4 block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Zur Startseite</button>
        </a>
      </div>
    </div>
  </div>
  <div class="p-6"></div>
</template>

<style>

h2{
  color: black;
}

.joinBeta{

}

</style>