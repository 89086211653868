<template>
  <div class="max-w-screen-sm m-auto">
    <div class="p-2"></div>
    <div class=" mt-16 mx-6 text-center">
      <h2 class="text-2xl font-semibold">Unsere Fragen & <br>deine Antworten</h2>
      <div class="p-2"></div>
      <div class="bg-softbg px-4 py-6 rounded-lg">
        <h4>Was wäre dein bevorzugtes Werkzeug im Kampf gegen die Zombies in The Walking Dead?
        </h4>
        <div class="p-2"></div>
        <textarea class="w-full rounded-lg py-4 px-4 text-center h-28 bg-white text-gray-400" placeholder="Meine Antwort lautet..."></textarea>
        <div class="p-2"></div>
        <button type="button" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Speichern und weiter</button>
        <router-link to="/profile/questions">
          <button type="button" class="rounded-md w-10/12 text-primary px-2.5 py-2.5 text-sm font-semibold">Zurück zur Übersicht</button>
        </router-link>
      </div>
    </div>
  </div>
  <div class="p-8"></div>
</template>