<template>
  <div class="bg-image mb-6">
    <div class="lg:flex justify-center items-center lg:h-screen mt-10 lg:m-0">
      <div class="max-w-2xl bg-white rounded-md w-full m-auto relative ">
        <div class="lg:pt-8"></div>
        <img class="m-auto w-full" style="max-width: 140px;" src="/assets/images/logo.png" alt="jadijdia">
        <div class="p-4"></div>
        <div class="slider lg:relative lg:block hidden">
          <div class="slider-container">
            <img :src="currentImage" alt="Slider Image" />
          </div>
          <div class="slider-navigation">
            <ul class="flex justify-center">
              <li v-for="(image, index) in images" :key="index" @click="changeImage(index)" :class="{ 'bg-black': index === currentIndex, 'bg-gray-400': index !== currentIndex }" class="w-2.5 h-2.5 rounded-full mx-1 cursor-pointer"></li>
            </ul>
          </div>
        </div>
        <div class="lg:hidden px-6">
          <swiper :slidesPerView="'auto'" :spaceBetween="30" :pagination="{ clickable: true, }" class="mySwiper">
            <swiper-slide><img src="/assets/images/landingpageImages/beforeLaunch/m_polystep1.png"></swiper-slide>
            <swiper-slide><img src="/assets/images/landingpageImages/beforeLaunch/m_polystep2.png"></swiper-slide>
            <swiper-slide><img src="/assets/images/landingpageImages/beforeLaunch/m_polystep3.png"></swiper-slide>
          </swiper>
        </div>
        <div class="p-6"></div>
        <div class="m-auto">
          <h4 class="text-center pb-4 text-xl">Start im September 2023</h4>
          <a class="flex justify-center" href="https://www.kickstarter.com/projects/20northgermany/polyroid-app-liebe-multiplizieren-nicht-limitieren" target="_blank">
            <button class="rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">
              Unterstütze Polyroid
            </button>
          </a>
          <div class="p-2"></div>
<!--          <button class="rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">-->
<!--            Zur Beta Anmelden-->
<!--          </button>-->
<!--          <router-link to="/" class="text-center block mt-4">-->
<!--            ➡️ Zur Startseite-->
<!--          </router-link>-->
        </div>
        <div class="p-6"></div>
        <footer-landingpage class=""></footer-landingpage>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import Footer from "@/components/footer/footer";
import FooterLandingpage from "@/components/footer/footerLandingpage";
import HeaderLandingpage from "@/components/header/headerLandingpage";

export default {
  data() {
    return {
      images: ['/assets/images/landingpageImages/beforeLaunch/polystep1.png', '/assets/images/landingpageImages/beforeLaunch/polystep2.png', '/assets/images/landingpageImages/beforeLaunch/polystep3.png'],
      currentIndex: 0,
      timer: null,
    };
  },
  components: {
    HeaderLandingpage,
    FooterLandingpage,
    Footer,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },
  mounted() {
    this.startSlider();
  },
  methods: {
    startSlider() {
      this.timer = setInterval(() => {
        this.nextImage();
      }, 7000);
    },
    stopSlider() {
      clearInterval(this.timer);
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    changeImage(index) {
      this.currentIndex = index;
      this.stopSlider();
      this.startSlider();
    },
  },
};
</script>


<style scoped>

  .bg-image::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("./../../public/assets/images/landingpageImages/landingpage-label-background.png");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.2;
  }

  @media(max-width: 1023px){
    .bg-image::before{
      background-image: unset
    }
  }

  .slider-container {
    width: 100%;
    height: auto;
    padding: 0 24px;
  }
  .slider-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .slider-navigation {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  /* Mobile Slider */
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: 80%;
  }

</style>