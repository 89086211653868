<template>
  <header class="bg-white">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Your Company</span>
          <img class="h-8 w-auto" src="/assets/images/logo.png" alt="" />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm font-semibold rounded-lg leading-6 text-gray-900 px-3 py-2.5 hover:bg-gray-50">{{ item.name }}</a>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end gap-x-2 items-center">
<!--        <router-link to="/login">-->
<!--          <span class="text-sm font-semibold rounded-lg leading-6 text-gray-900 px-3 py-2.5 hover:bg-gray-50">Anmelden</span>-->
<!--        </router-link>-->
<!--        <span class="font-medium">|</span>-->
        <router-link to="/register">
          <button class="block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer">Beta Registrierung ➔</button>
        </router-link>

      </div>
    </nav>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-10" />
      <DialogPanel class="fixed inset-y-0 right-0 z-10 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Your Company</span>
            <img class="h-8 w-auto" src="/assets/images/logo.png" alt="" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
            </div>
            <div class="py-6">
<!--              <router-link to="/login">-->
<!--                <span class="text-sm font-semibold rounded-lg leading-6 text-gray-900 px-3 py-2.5 hover:bg-gray-50">Anmelden</span>-->
<!--              </router-link>-->
<!--              <div class="p-2"></div>-->
              <router-link to="/register">
                <button class="flex justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Beta Registrierung ➔</button>
              </router-link>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>

</template>

<script>
/* eslint-disable */
  import { ref } from 'vue';
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronRightIcon } from '@heroicons/vue/20/solid'
  import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

  export default {
    name: "headerLandingpage",
    components: {Disclosure, DisclosureButton, DisclosurePanel, ChevronRightIcon, Bars3Icon, XMarkIcon},
    data(){
      return {
        mobileMenuOpen: ref(false),
        navigation: [
          { name: 'Home', href: '/', current: true },
          {
            name: 'Features',
            href: '#features',
            current: false,
          },
          {
            name: 'Spenden',
            href: '#donate',
            current: false,
          },
          {
            name: 'Advertise',
            href: '/',
            current: false,
          },
        ],
      };
    },
  }


</script>

<style>
.marker {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  background-color: black;
}
</style>