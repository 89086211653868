<template>
  <div class="">
    <div class="p-2"></div>
    <main class="max-w-screen-sm mt-16 m-auto">
      <h2 class="text-2xl text-center mx-6">Interactive with<br>your <span style="color: #EF1145">#happiness</span></h2>
      <div class="p-5"></div>
      <div class="mx-6">
        <h4 class="text-gray-500 font-medium text-lg mb-3">News</h4>
        <swiper :slidesPerView="'auto'" :spaceBetween="30" :pagination="{ clickable: true, }" class="mySwiper">
          <swiper-slide><img class="rounded-lg" src="/assets/images/dashboardImages/dashboardNewsOne.png"></swiper-slide>
          <swiper-slide class=""><img class="rounded-lg" src="/assets/images/dashboardImages/dashboardNewsTwo.gif"></swiper-slide>
          <swiper-slide class=""><img class="rounded-lg" src="/assets/images/dashboardImages/dashboardNewsOne.png"></swiper-slide>
        </swiper>
      </div>
      <div class="p-5"></div>
      <div class="mx-6">
        <h4 class="text-gray-500 font-medium text-lg mb-3">Rund um Polyamorie</h4>
        <div class=" bg-softbg rounded-lg relative my-4" :class="{'mt-8': news.ad}" v-for="news in polyNews" :key="news">
          <h3 v-if="news.ad" class="text-gray-400 text-xs font-light absolute -top-6 right-2">Anzeige</h3>
          <div class="grid grid-cols-12 gap-x-5 overflow-hidden pr-3 h-28 max-h-28 items-center rounded-lg">
            <img class="col-span-3 rounded-lg" :src="news.image">
            <div class="col-span-9 -mt-1">
              <h5 class="text-primary text-xs font-semibold pb-1">{{ news.subtitle }}</h5>
              <h4>{{ news.title }}</h4>
            </div>
          </div>
        </div>
      </div>


<!--      <h1 class="text-3xl font-bold underline">-->
<!--        Hello world! Test dd{{config.darkMode}}-->
<!--      </h1>-->
<!--      <h1>Hey {{user.firstname}}</h1>-->
<!--      <div class="bg-white dark:bg-black">-->
<!--        Test-->
<!--      </div>-->
    </main>
    <div class="p-20"></div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {notificationStore} from "@/store/notificationStore";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';

export default {
  name: "Dashboard",
  title: "Dashboard",
  components: {
    Swiper, SwiperSlide,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {
      auth,
      config,
      notification,
      user,
      modules: [Pagination],}
  },
  data(){
    return {
      polyNews: [
        {
          image: "/assets/images/dashboardImages/news/hollywoodtramp.png",
          title: "Piere Daily in einer Polyamoren Beziehung?",
          subtitle: "Hollywood Tramp",
          ad: true,
        },
        {
          image: "/assets/images/dashboardImages/news/beziehungsweiseunverblühmt-podcast.png",
          title: "Warum das Leben in einer Polybeziehung so gut ist!",
          subtitle: "Beziehungsweise Unverblühmt",
          ad: false,
        },
      ]
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {


  }
}
</script>

<style scoped>

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 80%;
}


</style>