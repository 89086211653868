<template>
  <div>
    <main class="max-w-md m-auto">
    <section class="login" v-if="page === 'start'">
      <div class="loginBox">
        <div class="titleBackgroundImg"></div>
        <div class="p-5"></div>
        <div class="text-center">
          <h2 class="text-2xl">Interactive with<br>your <span style="color: #EF1145">#happiness</span></h2>
          <div class="p-5"></div>
          <router-link to="/register">
            <button class="w-80 rounded-md border-2 border-primary text-primary px-2.5 py-2.5 text-sm font-semibold">Registrieren</button>
          </router-link>
          <div class="p-1"></div>
          <button @click="page = 'login'" type="button" class="w-80 rounded-md bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Anmelden</button>
        </div>
        <div class="p-4"></div>
        <div class="fixed bottom-0 left-0 right-0 flex justify-center gap-x-2 bg-white p-4">
          <a href="#">Impressum</a>
          <span>|</span>
          <a href="#">Datenschutz</a>
        </div>
      </div>
    </section>

    <section v-if="page === 'login'">
        <div class="pt-16 px-6">
          <div class="">
            <router-link to="/">
              <img class="w-full m-auto" style="max-width: 160px" src="/assets/images/logo.png" alt="Polyroid Logo">
            </router-link>
            <div class="p-8"></div>
            <h3 class="text-center text-2xl font-bold uppercase">Willkommen!</h3>
            <div class="p-1"></div>
            <h4 class="text-center text-md uppercase">Melde dich jetzt an</h4>
            <div class="p-2"></div>
            <form class="loginForm" @submit.prevent="login()" method="POST">
              <div class="">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <input v-model="email" id="email" name="email" type="email" autocomplete="email" class="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
              <div class="p-2"></div>
              <div class="">
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" class="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
              <div class="p-3"></div>
              <div class="flex justify-between ">
                <div class="flex gap-x-2 items-center" v-if="!config.cordova">
                  <input v-model="cookie" id="remember" name="remember" type="checkbox" class="pl-4 h-4 w-4 rounded border-gray-300 text-primary focus:ring-indigo-600">
                  <label for="remember" class="block text-sm leading-6 text-gray-900">Eingeloggt bleiben</label>
                </div>
                <div class="">
                  <a style="cursor: pointer;" @click="page = 'forgot'" class="text-sm leading-6 text-gray-900">Password vergessen?</a>
                </div>
              </div>
              <div class="p-3"></div>
              <div class="pb-4">
                <div v-if="loginStatus === 'fail'">
                  <alert
                      type="error"
                      title="Anmeldung fehlgeschlagen"
                      message="Bitte überprüfe deine Angaben."
                  />
                </div>

              </div>
              <div>
                <button type="submit" class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Sign in</button>
              </div>
              <div class="p-3"></div>
              <div class="orWrapper flex justify-center items-center gap-x-4">
                <hr class="w-full opacity-30"/>
                <p>oder</p>
                <hr class="w-full opacity-30"/>
              </div>
              <div class="p-3"></div>
              <router-link to="/register">
                <button class="flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 ">Kostenlos Registrieren</button>
              </router-link>
            </form>
          </div>

        </div>

    </section>

    <section class="login" v-if="page === 'forgot'">
      <div class="pt-16 px-6">
        <div class="">
          <img class="w-full m-auto" style="max-width: 240px" src="/assets/images/logo.png" alt="Polyroid Logo">
          <div class="p-8"></div>
          <h3 class="text-center text-lg font-bold uppercase">Password vergessen?</h3>
          <div class="p-1"></div>
          <div class="p-2"></div>
          <h2 class="f-normal pb-1">
            Bitte gib deine E-Mail-Adresse ein
          </h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input class="block pl-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="email" name="email" placeholder="E-Mail-Adresse" required v-model="email">
          </div>
        </div>
        <p class="feedbackmessage t-center pad-8 color-blue" v-if="forgotStatus === 'ok'">
          Sofern dein Account mit dieser E-Mail verbunden ist, erhälst du eine E-Mail.
        </p>
        <div class="t-center pad-16 width-300" style="margin-top: 20px;">
          <button @click="forgotPassword" class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" :disabled="resetLoading" :class="{disabled: resetLoading}">
            Passwort zurücksetzen
          </button>
          <div class="p-3"></div>
          <div class="orWrapper flex justify-center items-center gap-x-4">
            <hr class="w-full opacity-30"/>
            <p>oder</p>
            <hr class="w-full opacity-30"/>
          </div>
          <button type="button" style="border: unset;" class="secondButton" @click="page = 'login'">
            Zurück
          </button>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'resetpassword'">
      <div class="content-500">
        <div class="t-center pad-32">
          <h1 class="f-normal">
            Neues Passwort erstellen
          </h1>
          <h2 class="color-blue">
            Bitte gib dein neues Passwort ein:
          </h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input type="password" class="color-white" name="password" placeholder="Passwort" required v-model="pw1">
          </div>
          <div class="field simple pad-8">
            <input type="password" class="color-white" name="password2" placeholder="Passwort wiederholen" required v-model="pw2">
          </div>
          <p class="feedbackmessage t-center pad-8 color-petrol" v-if="resetStatus === 'ok'">
            Dein Passwort wurde erfolgreich geändert.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="!passwordCheck && pw2.length > 0">
            Die Passwörter stimmen nicht überein oder sind nicht mindestens 8 Zeichen lang.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="resetStatus === 'invalid'">
            Der Link ist abgelaufen. Bitte fordere einen neuen an.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="resetStatus === 'password'">
            Ups, da lief was schief.
          </p>
        </div>
        <div class="t-center pad-16" v-if="resetStatus !== 'ok'">
          <button @click="resetPassword" class="button bgcolor-blue color-white circular" :disabled="!passwordCheck">
            Passwort speichern
          </button>
          <div class="divider">
            oder
          </div>
          <button type="button" class="button bgcolor-second color-white circular" @click="page = 'login'">
            Zurück
          </button>
        </div>
        <div class="t-center pad-16" v-else>
          <button @click="page = 'login'" class="button color-white circular">
            Zum Login
          </button>
        </div>
      </div>
    </section>
  </main>
  </div>
</template>


<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import alert from "@/components/alerts/alert";
import { CheckCircleIcon, XMarkIcon } from '@heroicons/vue/20/solid';

export default {
  name: "Login",
  title: "Login",
  components: {CheckCircleIcon, XMarkIcon, alert},
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      page: this.$route.query.page ?? 'start',
      token: this.$route.query.resetkey,
      cookie: this.config.cordova,
      email: '',
      password: '',
      pw1: '',
      pw2: '',
      loginStatus: '',
      forgotStatus: '',
      resetStatus: '',
      resetLoading: false
    }
  },
  computed:{
    passwordCheck(){
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  watch: {
    email(){
      this.resetLoading = false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    login(){
      const _this = this
      this.auth.login(this.email, this.password, this.cookie)
          .then((response) => {
            console.log(response)
            if(response === 250){
              _this.loginStatus = 'ok'
              if(_this.$route.query.redirect){
                _this.$router.push(_this.$route.query.redirect)
              }else{
                _this.$router.push('Dashboard')
              }
            }else if(response === 251){
              _this.loginStatus = 'user'
            }else if(response === 252){
              _this.loginStatus = 'password'
            }
          })
          .catch(() => {
            _this.loginStatus = 'fail'
          })
    },
    //reset password
    forgotPassword() {
      this.resetLoading = true
      const _this = this
      this.auth.resetPassword(this.email)
          .then((response) => {
            if(response === 250){
              _this.forgotStatus = 'ok'
            }else if(response === 251){
              _this.forgotStatus = 'email'
            }else if(response === 252){
              _this.forgotStatus = 'fail'
            }
          })
          .catch(() => {
            _this.forgotStatus = 'fail'
            _this.resetLoading = false
          })
    },
    //restore password
    resetPassword() {
      const _this = this
      this.auth.restorePassword(this.token, this.pw1)
          .then(r => {
            if(r === 250){
              _this.resetStatus = 'ok'
            }else if(r === 251){
              _this.resetStatus = 'invalid'
            }else if(r === 252){
              _this.resetStatus = 'password'
            }
          })
          .catch(() => {
            _this.resetStatus = 'password'
          })
    }
  }
}
</script>

<style scoped>

  .titleBackgroundImg{
    background-image: url("../../public/assets/images/polyamorous-couple-min.jpg");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;

  }

</style>