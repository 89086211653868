import { defineStore } from 'pinia'

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        email: '',
        firstname: '',
        myGender: '',
        dob: '',
        avatar: '',
    }),
})
