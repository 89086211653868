<template>
  <div class="max-w-screen-sm m-auto">
    <div class="p-2"></div>
    <div class="mt-16 mx-6 text-center">
      <h2 class="text-center font-semibold text-2xl">Unsere Fragen & <br>deine Antworten</h2>
      <div class="p-2"></div>
      <p class="text-center">Erleichtere deinem Gegenüber den Einstieg mit dir und beantworte bis zu 5 unserer kreativen Fragen.</p>
      <div class="p-1.5"></div>
      <router-link to="/profile/questions/new">
        <button type="button" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Neue Frage beantworten</button>
      </router-link>
      <div class="p-5"></div>
      <div class="">
        <h4 class="text-center font-semibold text-xl">Deine bisherigen Antworten</h4>
        <div class="p-4"></div>
<!--        <div  class=" ">-->
        <div v-for="userAnswers in userAnswer" :key="userAnswers" class="text-center bg-softbg rounded-lg px-4 py-4">
          <h6 class="text-primary font-semibold">{{ userAnswers.question}}
          </h6>
          <div class="p-2"></div>
          <textarea v-model="userAnswers.answer" :readonly="!editable" :class="{'text-black': editable, 'bg-white text-gray-400': !editable }" class="w-80 rounded-lg py-4 px-4 text-center h-28" placeholder="Meine Antwort lautet..."></textarea>

          <div class="p-2"></div>
          <button @click="editButtonHandler" v-if="!editDiv" type="button" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Antwort bearbeiten</button>
          <div v-if="editDiv" class="flex flex-row-reverse">
            <button @click="safeButtonHandler" type="button" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Speichern</button>
            <button type="button" class="rounded-md w-10/12 text-primary px-2.5 py-2.5 text-sm font-semibold">Löschen</button>
          </div>
        </div>
      </div>
      <div class="p-2"></div>
      <router-link to="/profile">
        <button type="button" class="rounded-md w-10/12 text-primary px-2.5 py-2.5 text-sm font-semibold">Zurück zum Profil</button>
      </router-link>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        editDiv: false,
        editable: false,
        userAnswer: [
          {
            question:'Würdest du lieber auf einer einsamen Insel festsitzen oder in einer Großstadt leben?',
            answer: 'Großstadt 🏢',
          }
        ],
      };
    },

    mounted() {
      console.log(this.userAnswer); // Ausgabe der Nachricht in der Konsole
    },

    methods: {
      showEdit() {
        this.editDiv = true;
      },
      hidEdit() {
        this.editDiv =false;
      },
      enableEditing() {
        this.editable = true;
      },
      editButtonHandler() {
        this.showEdit();
        this.enableEditing();
      },
      safeButtonHandler() {
        this.disableEditing();
        this.hidEdit();
      },
      disableEditing() {
        this.editable = false;
      },
    }
  }


</script>

