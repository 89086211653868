<template>
  <footer @click="supportModal = false;">
    <div class="footerWrapper">
      <router-link to="/dashboard" class="matchgame footerIcon" >
        <div class="footerIcons" :class="{active: isActive('/dashboard')}">
          <img src="/assets/images/icons/homeicons.png" alt="Polyroid Icon for Dashboard">
        </div>
        <span>Home</span>
      </router-link>
      <router-link to="/matchgame" class="matchgame footerIcon" >
        <div class="footerIcons" :class="{active: isActive('/matchgame')}">
          <img src="/assets/images/icons/favorite_FILL1_wght400_GRAD0_opsz48.png" alt="Polyroid Icon for Matchgame">
        </div>
        <span>Match</span>
      </router-link>
      <router-link to="/messenger" class="chat footerIcon" >
        <div class="chat">
          <div class="footerIcons" :class="{active: isActive('/messenger')}">
            <img src="/assets/images/icons/chat_FILL1_wght400_GRAD0_opsz48.png" alt="Polyroid Icon for Chat">
          </div>
          <div class="UnreadChats" data-badge="5"></div>
        </div>
        <span>Chat</span>
      </router-link>
      <router-link to="/profile" class="profile footerIcon" >
        <div class="footerIcons" :class="{active: isActive('/profile')}">
          <img src="/assets/images/icons/person_FILL1_wght400_GRAD0_opsz48.png" alt="Polyroid Icon for Profile">
        </div>
        <span>Profil</span>
      </router-link>
    </div>


  </footer>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Footer",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  computed: {
  },

  methods: {
    isActive(path) {
      return this.$route.path === path;
    }
  }
}
</script>

<style scoped>
footer{
  position: fixed;
  bottom: 20px;
  z-index: 60;
  width: 100%;
}

.footerWrapper{
  background: rgba( 255, 255, 255, 0.2 );
  backdrop-filter: blur( 11.5px );
  -webkit-backdrop-filter: blur( 11.5px );
  padding: 12px 8px;
  display: flex;
  align-items: end;
  justify-content: center;
  width: 90%;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 120px;
}

.footerWrapper img{
  width: 100%;
  max-width: 18px;
}

.footerWrapper .footerIcon {
  display: flex;
  flex-wrap: wrap;
  column-gap: 100%;
  justify-content: center;
  row-gap: 3px;
  flex: 1;
}

.chat{
  position: relative;
}

.UnreadChats {
  position: absolute;
  background-color: var(--secondaryBlue);
  bottom: -1px;
  right: 4px;
  width: 20px;
  height: 20px;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

}

.UnreadChats::before {
  content: attr(data-badge);
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: black;
}

.footerWrapper .active {
  background-color: var(--secondaryBlue);
  border-radius: 128px;
}

.footerWrapper .footerIcons{
  padding: 8px 16px;
}

.footerWrapper .footerIcons img{
  max-width: 16px;
  width:100%;
}

@media (min-width: 400px){
  .footerWrapper .footerIcons{
    padding: 8px 18px;
  }
}
@media (min-width: 500px){
  .footerWrapper .footerIcons{
    padding: 8px 28px;
  }
}
</style>