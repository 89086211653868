<template>
  <div>
    <main class="content-500" id="profil">
      <div class="p-2"></div>
      <section class="profileImgWrapper">
        <div class="w-full m-auto " style="max-width: 500px">
          <!--          <img :src="config.projectURL + 'assets/images/users/' + user.avatar">-->
          <!--          <img :src="config.projectURL + 'assets/images/users/avatar.jpg'">-->
          <img class="rounded-2xl" src="/assets/images/users/polyroid-avatar-placeholder.jpg">
        </div>
      </section>
      <div class="p-2"></div>
      <section class="bg-softbg rounded-2xl pt-4 pb-3 text-center">
        <h2 class="font-semibold text-xl">{{user.firstname}}</h2>
        <div class="p-1"></div>
        <!--          Polyprofil wechseln-->
        <div class="">
          <span class="cursor-pointer bg-secondary text-primary font-medium text-xs py-1.5 px-3 w-4/12 m-auto rounded-full " @click="toggleProfile">Profil wechseln</span>
          <div class="changeProfile" v-show="showProfile">
            <h6 class="mt-4 mb-2 text-md">Profile</h6>
            <div class="flex justify-center items-center gap-x-2 bg-white w-80 m-auto rounded-2xl py-2 my-3 border-primary border-2">
              <img class="w-full rounded-full" style="max-width: 42px;" src="/assets/images/users/polyroid-avatar-placeholder.jpg" alt="">
              <span class="cursor-pointer bg-secondary text-primary font-medium text-xs py-1.5 px-3 rounded-full">{{user.firstname}}</span>
            </div>
            <div class="flex justify-center items-center gap-x-2 bg-white w-80 m-auto rounded-2xl py-2 my-3">
              <img class="w-full rounded-full" style="max-width: 42px;" src="/assets/images/users/polyroid-avatar-placeholder.jpg" alt="">
              <span class="cursor-pointer bg-secondary text-primary font-medium text-xs py-1.5 px-3 rounded-full">DieHHBoys</span>
            </div>
            <div class="flex items-center justify-center gap-x-4 w-80 m-auto mt-4">
              <button type="button" class="rounded-md bg-primary w-full text-white px-6 py-2.5 text-sm font-semibold">Erstellen</button>
              <button type="button" class="rounded-md bg-primary w-full text-white px-6 py-2.5 text-sm font-semibold">Beitreten</button>
            </div>
          </div>
        </div>
<!--        Untersützerbadge-->
        <div class="hidden cursor-pointer bg-gradient-to-r from-primary to-red-500 font-medium text-xs py-1.5 px-3 w-4/12 m-auto rounded-full">
          <h2 class="text-white">Unterstützer*in</h2>
        </div>
      </section>
      <div class="p-3"></div>
      <div class="m-auto text-center">
        <button type="button" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Bilder bearbeiten</button>
      </div>
      <div class="p-4"></div>
      <section class="bg-softbg px-5 rounded-2xl">
        <h3 class="text-center text-xl font-bold pt-6">Kurzbeschreibung</h3>
        <form class="text-center relative h-fit pt-4">
          <Vue3Lottie v-if="descriptionLoading" :loop="true" :animationData='rainbowLoader' class="absolute z-10 h-24 w-24 top-0 left-40" />
          <textarea class="w-full bg-white rounded-md pt-3 px-3 text-center h-40 resize-none mb-4" v-model="description" id="userDescription" name="userDescription" placeholder="Erzähl etwas über dich..."></textarea>
          <button type="button" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Beschreibung speichern</button>
          <div class="p-2"></div>
        </form>
      </section>
      <div class="p-4"></div>
      <!--      label myProfile-->
      <section class="bg-softbg px-6 py-5 rounded-2xl">
        <h4 class="mb-2 text-gray-500">Die Basics</h4>
        <div class="flex justify-start align-center gap-x-2 gap-y-2 flex-wrap">
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block" v-for="sexualitie in selectSexualities" :key="sexualitie">
            {{ sexualities.find(({id})=> id === parseInt(sexualitie))?.title }}
          </span>
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block">
            {{ selectGender }}
          </span>
          <button class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block">✍️ Labels auswählen</button>
        </div>
        <div class="p-3"></div>
        <h4 class="mb-2 text-gray-500">Meine Interessen</h4>
        <div class="flex justify-start align-center gap-x-2 gap-y-2 flex-wrap">
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block" v-for="interest in selectInterests" :key="interest">
            {{ interests.find(({id})=> id === parseInt(interest))?.emoji }}
            {{ interests.find(({id})=> id === parseInt(interest))?.title }}
          </span>
          <button class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block">✍️ Labels auswählen</button>
        </div>
        <div class="p-3"></div>
        <h4 class="mb-2 text-gray-500">Sprachen, die ich spreche</h4>
        <div class="flex justify-start align-center gap-x-2 gap-y-2 flex-wrap">
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block" v-for="language in selectLanguages" :key="language">
            {{ languages.find(({id})=> id === parseInt(language))?.emoji }}
            {{ languages.find(({id})=> id === parseInt(language))?.title }}
          </span>
          <button class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block">✍️ Labels auswählen</button>
        </div>

<!--          Standart auswahl-->
<!--          <select v-model="selectLanguage" multiple>-->
<!--            <option v-for="language in languages" :key="language.id">{{language.title}}</option>-->
<!--          </select>-->

      </section>
      <!--      label Profile-->
      <section class="bg-softbg px-6 py-5 rounded-2xl hidden">
        <h4 class="mb-2 text-gray-500">Die Basics</h4>
        <div class="flex justify-start align-center gap-x-2 gap-y-2 flex-wrap">
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block" v-for="sexualitie in selectSexualities" :key="sexualitie">
            {{ sexualities.find(({id})=> id === parseInt(sexualitie))?.emoji }}
            {{ sexualities.find(({id})=> id === parseInt(sexualitie))?.title }}
          </span>
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block">
            {{ selectGender }}
          </span>
          <button class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block">✍️ Labels auswählen</button>
        </div>
        <div class="p-6"></div>
        <h4 class="mb-2 text-gray-500">Meine Interessen</h4>
        <div class="flex justify-start align-center gap-x-2 gap-y-2 flex-wrap">
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block" v-for="interest in selectInterests" :key="interest">
            {{ interests.find(({id})=> id === parseInt(interest))?.emoji }}
            {{ interests.find(({id})=> id === parseInt(interest))?.title }}
          </span>
        </div>
        <div class="p-6"></div>
        <h4 class="mb-2 text-gray-500">Sprachen, die ich spreche</h4>
        <div class="flex justify-start align-center gap-x-2 gap-y-2 flex-wrap">
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block" v-for="language in selectLanguages" :key="language">
            {{ languages.find(({id})=> id === parseInt(language))?.emoji }}
            {{ languages.find(({id})=> id === parseInt(language))?.title }}
          </span>
        </div>
        <div class="p-6"></div>
        <h4 class="mb-2 text-gray-500">📍 Standort</h4>
        <div class="flex justify-start align-center gap-x-2 gap-y-2 flex-wrap">
          <span class="bg-white rounded-full text-md px-2.5 py-1.5 inline-block">
            {{ user.fromCity }}
          </span>
        </div>


        <!--          Standart auswahl-->
        <!--          <select v-model="selectLanguage" multiple>-->
        <!--            <option v-for="language in languages" :key="language.id">{{language.title}}</option>-->
        <!--          </select>-->

      </section>

      <div class="p-6"></div>
      <img class="rounded-2xl" src="/assets/images/users/polyroid-avatar-placeholder.jpg">
      <div class="p-6"></div>
<!--      QAndA myProfile-->
      <section class=" text-center">
        <div class="">
          <h2 class="text-center text-xl font-bold">Fragen und Antworten</h2>
          <div class="p-1.5"></div>
          <p>Beantworte ein paar Fragen und erleichtere anderen Nutzern den Start mit dir</p>
          <div class="p-2"></div>
          <router-link to="/profile/questions/new">
            <button type="button" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">Jetzt starten</button>
          </router-link>
          <div class="p-1"></div>
          <router-link to="profile/questions">
            <button type="button" class="rounded-md w-10/12 text-primary px-2.5 py-2.5 text-sm font-semibold">Beantwortete Fragen bearbeiten</button>
          </router-link>
          <div class="p-4"></div>
          <h6 class="text-gray-500">Bisherige Antworten</h6>
          <div class="p-2"></div>
        </div>
      </section>
      <div class="">
        <div class="bg-softbg rounded-2xl px-6 py-8 text-left">
          <p class="text-gray-500 text-sm">Würdest du lieber auf einer einsamen Insel festsitzen oder in einer Großstadt leben?</p>
          <div class="p-1"></div>
          <p>Ich würde lieber auf einer einsamen Insel mit meinen liebsten leben. Also natürlich nur, wenn es auch Interent gibt.</p>
        </div>
      </div>
      <div class="p-6"></div>
      <img class="rounded-t-2xl" src="/assets/images/users/polyroid-avatar-placeholder.jpg">
      <div class="grid grid-cols-2">
        <img class="rounded-bl-2xl" src="/assets/images/users/polyroid-avatar-placeholder.jpg">
        <img class="rounded-br-2xl" src="/assets/images/users/polyroid-avatar-placeholder.jpg">
      </div>
      <div class="p-6"></div>
      <div class="">
        <div class="bg-softbg rounded-2xl px-6 py-8 text-left">
          <p class="text-gray-500 text-sm">Würdest du lieber auf einer einsamen Insel festsitzen oder in einer Großstadt leben?</p>
          <div class="p-1"></div>
          <p>Ich würde lieber auf einer einsamen Insel mit meinen liebsten leben. Also natürlich nur, wenn es auch Interent gibt.</p>
        </div>
      </div>

      <div class="p-6"></div>
      <div class="text-center">
        <button @click="auth.logout()" class="rounded-md w-10/12 bg-primary text-white px-2.5 py-2.5 text-sm font-semibold">
          Logout
        </button>
      </div>
      <div class="p-20"></div>

    </main>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import axios from "axios";
import {Vue3Lottie} from "vue3-lottie";
import rainbowLoader from '@/assets/lotties/rainbowLoader.json'

export default {
  name: "Profil",
  title: "Profil",
  components: {
    Vue3Lottie,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      rainbowLoader,
      showProfile: false,
      isModalOpen: false,
      description:'',
      descriptionLoading:false,
      selectLanguages:[],
      languages:[],
      selectSexualities: [],
      sexualities:[],
      selectInterests:[],
      interests:[],
      selectGender:[],
      fromCity:[],
    }
  },
  mounted() {
    this.config.loading = false
    this.getProfileDatas()
    this.getProfileLabels()
  },
  methods: {
    toggleProfile() {
      this.showProfile = !this.showProfile;
    },
    //Hole mir die profildaten
    getProfileDatas(){
      this.descriptionLoading = true
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        axios.post(this.config.projectURL+'profile/getUserData',fD)
            .then((response) => {
              if (response.status === 250){
                this.descriptionLoading=false
                console.log(response.data)
                this.description = response.data.description

                //Teile den string auf in einen Array mit den Werten
                const langArr = response.data.languages?.split(',')
                const langArrr = response.data.interests?.split(',')
                // Entferne das letzte Element aus dem Array
                langArr.pop()
                langArrr.pop()
                //Die selectLanguages sind nun der langArr
                this.selectLanguages = langArr
                this.selectSexualities = response.data.sexuality
                this.selectInterests = langArrr
                switch (response.data.myGender) {
                  case 'diverse': {
                    this.selectGender = 'Divers'
                    break;
                  }
                  case 'male': {
                    this.selectGender = 'Männlich'
                    break;
                  }
                  case 'female': {
                    this.selectGender = 'Weiblich'
                    break;
                  }
                }
                console.log(this.selectInterests);
              }
            })
    },
    //Hole mir die Profillabels
    getProfileLabels(){
      this.descriptionLoading = true
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      axios.post(this.config.projectURL+'profile/getProfileLists',fD)
          .then((response) => {
            if (response.status === 250){
              this.descriptionLoading=false
              console.log(response.data)
              this.languages = response.data.languages
              this.sexualities = response.data.sexualities
              this.interests = response.data.interests
              // console.log(this.languages)sexualities
            }
          })
      },


  }
}
</script>

<style scoped>

  #profil{
    margin: 80px 18px 0;
  }

  .profileImgWrapper .profileImg img{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 12px;
  }

  #profil .changeProfileImg{
    margin: 24px 0;
  }

  #profil .userDescription{
    background-color: #F4F8FD;
    padding: 0 18px;
    border-radius: 12px;
  }

  #profil .userDescription h3{
    font-size: 18px;
    font-weight: 600;
    padding: 24px 0 16px;
  }

  #profil .userDescription textarea{
    max-width: 100%;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    padding: 10px 8px 0;
    word-wrap: break-word;
    height: 100px;
    resize: none;
    margin-bottom: 12px;
  }

  #profil .userDescription button{
    margin-bottom: 12px;
  }

  #profil .userLabel{

  }

  #profil .userLabel .labelOverview{
    padding: 18px 0;
  }

  #profil .userLabel .labelWrapper{
    display: flex;
    justify-content: start;
    column-gap: 6px;
    row-gap: 4px;
    flex-wrap: wrap;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  #profil .userLabel .labelWrapper span{
    display: inline-block;
    background-color: white;
    border-radius: 120px;
    font-size: 14px;
    padding: 4px 8px;
  }

  .labelModal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Hintergrundfarbe mit Transparenz */
    backdrop-filter: blur(5px); /* Hintergrundunschärfe */
    z-index: 9998; /* Hinter dem Modal, aber über dem Rest der Seite */
    overflow: auto;
  }

  .labelModalInner {
    background-color: white;
    padding: 16px;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    border-radius: 12px;
    overflow: scroll;
  }

  .labelModalInner .buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  .labelModalInner .buttons .save{
    color: var(--primaryButtonColor)
  }

  .labelModal-content h3{
    font-weight: 600;
    padding: 12px 0 8px;
  }





  .signOutButton{
    margin-bottom: 120px;

  }


</style>